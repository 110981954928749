import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App2.css';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['de'], { type: 'region' }); // German locale

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://bouncy.ai/kellyg';
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setFetchAttempted(true);

    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));

        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <div className="landing-container">
      {/* Video Background */}
      <video
        autoPlay
        playsInline
        preload="auto"
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: -1,
        }}
      >
        <source src="https://ofmpics.com/img-3221_F3Al5uP0-ezgif.com-crop-video.mp4" type="video/mp4" />
        Dein Browser unterstützt das Video-Tag nicht.
      </video>

      {/* Main Content */}
      <div className="container">
        <a className="header-logo" href="https://bouncy.ai/kellyg">
          <div className="onlyfans"></div>
        </a>
        <img
          src="https://i.ibb.co/bWR1MTx/photo-2025-01-17-23-21-45-modified.png"
          alt="Kopfzeilenbild"
          className="header-image"
        />

        <div className="textContainer">
          <section>
            <div className="username"><strong>Kelly 💘</strong></div>
            <div className="useraccountname">@kelly.klitta</div>

            <div id="user-status" className="user-status">
              <div className="status"></div>
              <div id="available"><strong>Jetzt verfügbar</strong></div>
              <div className="separator"></div>
              <div className="clock"></div>
              <div className="respond-time"><strong>Antwortet in 2 Minuten</strong></div>
            </div>
          </section>
        </div>

        <div className="textContainer">
          <div className="location"></div>
          <span id="location-name"><strong>{city ? `${city} ${region}` : '<3'}</strong></span>
        </div>

        <div className="textContainer2">
          <div className="time-in-current-location">
            <strong>Dein schwedisches Lieblingsmädchen lebt in {city || 'deiner Stadt'} 🇸🇪 Schreib mir jetzt 💙💛.</strong>
          </div>
        </div>

        <div className="textContainer3">
          <a href="https://bouncy.ai/kellyg" id="customButton">
            <div className="onlyfans"></div>
            Schreib mir hier
          </a>
        </div>

        <div className="countdown-block">
          <span className="discount"><strong>JETZT GRATIS </strong></span>
          <span className="timer-end"><strong>endet in</strong> </span>
          <span id="timer"><strong>{formatTimeLeft(timeLeft)}</strong></span>
        </div>

        <Analytics />
      </div>
    </div>
  );
}

export default App;
